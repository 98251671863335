<template>
    <widget-container class="widget-iframe-container" name="Iframe" v-if="widget" :widget="widget">
        <template v-if="widget.loaded">
            <iframe class="iframe fullscreen" :src="widget.config.iframe_url"></iframe>
        </template>
    </widget-container>
</template>

<script>
import {Widget} from "../helpers/Widget.js";

export default {
    name: `widget-iframe`,
    data() {
        return {
            widget: null
        }
    },
    async mounted() {
        this.widget = new Widget(`iframe`, this.$route.params.id);
        await this.widget.load();
    }
}
</script>

<style lang="scss" scoped>
</style>
